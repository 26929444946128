import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCogs,
    faCloud,
    faGraduationCap,
    faBriefcase,
    faSchool
} from "@fortawesome/free-solid-svg-icons";
import ReactSkillbar from 'react-skillbars';
import Projects from "./Projects";


const menuItems = [
    { index: 0, title: "Education", icon: faGraduationCap },
    { index: 1, title: "Projects", icon: faCloud },
    { index: 2, title: "Activities", icon: faSchool },
    { index: 3, title: "Work experience", icon: faBriefcase },
    { index: 4, title: "skills", icon: faCogs },
    // { index: 4, title: "Interests", icon: faCogs }
];

const skills = [
    { type: "Java", level: 85 },
    { type: "Python", level: 80 },
    { type: "Javascript", level: 80 },
    { type: "HTML", level: 75 },
    { type: "React", level: 70 },
    { type: "CSS", level: 70 },
    { type: "C", level: 65 },
]
const colors = {
    bar: "#f58025",
    title: {
        text: "#fff",
        background: "inherit"
    }
}
// class ResumeSidebar extends React.Component {
//     constructor() {
//         super();

//         this.state = {
//             activeIndex: 0
//         }
//     }
//     render() {
const styles = {
    btn: {
        color: '#313131',
        backgroundColor: 'white',
    },
    btnActive: {
        color: 'white',
        backgroundColor: '#f58025',
    }
}
const ResumeSideBar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className='container'>
            <div className="row resumeContainer">
                <div className='col-sm-4 col-md-3'>
                    <div className="sidebar">
                        <ul>
                            {menuItems.map(item => (
                                <li key={item.title}>
                                    <button className="sidebar__listItem" 
                                    onClick={() => {setActiveIndex(item.index)}
                                    }
                                        style={(activeIndex === item.index) ? styles.btnActive : styles.btn}>
                                        <FontAwesomeIcon className={"sidebar__icon"} icon={item.icon} />
                                        <div className={"sideBarBtn" + (activeIndex === item.index ? "" : " underline-hover-effect")}>{item.title}</div>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {activeIndex === 0 &&
                    <div className='col-sm-8 col-md-9 edSkills'>
                        <div className="timeline">

                            <div className="row edInst te-primary">
                                <div className='col-sm-12 col-md-3'>
                                    <img width="125" height="125" className='edImage' src="https://disruptingafrica.com/images/6/65/African-Leadership-Academy-logo.png" alt="Italian Trulli" />
                                </div>
                                <div className='col-sm-12 col-md-9 edText'>
                                    <h5 className="event-date">2018-2020</h5>
                                    <h4 className="event-name">Entrepreneurship & Cambridge A-levels</h4>
                                    <span className="event-description">African Leadership Academy</span>
                                    <p>Accelerated mathematics, Physics, and Economics.</p>
                                </div>
                            </div>

                            <div className="row edInst te-primary">
                                <div className='col-sm-12 col-md-3'>
                                    <img width="125" height="125" className='edImage' src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Princeton_seal.svg" alt="Italian Trulli" />
                                </div>
                                <div className='col-sm-12 col-md-9 edText'>
                                    
                                    <h5 className="event-date">2020-2024</h5>
                                    <h4 className="event-name">Computer Science</h4>
                                    <span className="event-description">Princeton University</span>
                                    <p>Minor: Applied and Computational Mathematics.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeIndex === 4 &&
                    <div className='col-sm-8 col-md-9 edSkills'>
                        <ReactSkillbar height={20} skills={skills} colors={colors} />
                    </div>
                }

                {activeIndex === 3 &&
                    <div className='col-sm-8 col-md-9 activities'>
                        <b>Work Experience</b>
                        <div>
                            At Princeton University, I served as a Teaching Assistant for COS 226
                            (Algorithms and Data Structures) and COS 217 (Introduction to Programming Systems),
                            assisting students in core classes in the CS department debug their assignments. I interned
                            at<a href="https://www.hbku.edu.qa/en/qcri/about">Qatar Computing Research Institute</a>as a data scientist, working on predicting the bias and factuality
                            of news websities using website’s certificate and hosting features, reaching 72% accuracy for factuality and 66% for bias.
                            I also worked as a data scientist at QuantCap, a start-up that uses machine learning to predict horse competitions,
                            stocks as well as Options. I worked in the Options teams, using Volatility to predict Options prices. I had multiple other research assisstant positions.
                            I worked in the Princeton School of Public and International Affairs analyysing birds' migration pictures
                            and with the Arab Barometer compilining interview question for the largest repository of publicly available data on citizens' views in MENA.</div>
                        <div>I am currently interning at the Helmholtz Institutes in Germany, at the<a href="https://www.fz-juelich.de/en">Jülich
                            Supercomputing Center</a>. I am working as a part of their<a href="https://github.com/helmholtz-analytics/heat">Heat</a>library for data science, using parallel computing to create
                            distributed Sparse matrices for huge data that are easy to use for Scipy and Torch users.</div>
                    </div>
                }
                {activeIndex === 2 &&
                    <div className='col-sm-8 col-md-9 activities'>
                        <b>Leadership Positions</b>
                        <div> I am pursuing my interest in computer science outside of academics, working as
                            a software Engineer in<a href="https://www.dailyprincetonian.com/">Daily Princetonian</a>in both design team and Business team.
                            I co-designed the<a href="https://projects.dailyprincetonian.com/queer-remembrances-queer-futures/">queer rememberence project</a>and I am working on a new project with the Business team.
                            I am also working as a web developer in the room draw project, a web application where
                            Princeton University student can submit and read room reviews before they choose their rooms every semester,
                            led by<a href="https://www.princetonresinde.com/">ResInDe</a>. I am also part of Princeton NeroTech club, thanks to which, I co-founded Cruise. I learnt more about how to work with OpenBCI and brain waves analysis.
                            For my Frist two years at Princeton, I worked as a treasurer for<a href="https://princetoneclub.com/">Princeton Entrepreneurship club</a>,
                            one of the biggest club at Princeton, managing an over $500k budget. I was in charge of finance
                            communication between 15 sub-teams, the leadership team, and Princeton administration. In my free time, I record covers in Arabic, French, and English.</div>
                        <div>Next semester, I will be serving as a Senior Staffer on the Technology Team for
                            the<a href="https://ic.businesstoday.org/">Business Today International Conference.</a></div>
                    </div>
                }

                {activeIndex === 1 &&
                    <div className='col-sm-8 col-md-9'>
                        <Projects />
                    </div>

                }
            </div>
        </div>

    )
}
// };

export default ResumeSideBar;