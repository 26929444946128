import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'


import ResumeSidebar from './ResumeSideBar'

const Resume = () => {
    return (
        <div className='Resume' id='Resume'>
            <div className="row section-intro">
                <div className="col-twelve">
                    <h5>Resume</h5>
                    <h1>More of my credentials.</h1>

                    <p className="lead">Here are my work experiences and education.</p>

                </div>
            </div>
            <ResumeSidebar />

            {/* <div className="row edSkills">
                <div className="col-sm-6 col-md-8 subpage-block">
                    <div className="block-title">
                        <h3>Education</h3>
                    </div>
                    <div className="timeline">

                        <div className="row edInst te-primary">
                            <div className='col-sm-12 col-md-3'>
                            <img width="175" height="175" className='edImage' src="https://disruptingafrica.com/images/6/65/African-Leadership-Academy-logo.png" alt="Italian Trulli" />
                            </div>
                            <div className='col-sm-12 col-md-9 edText'>
                                <h5 className="event-date">2018-2020</h5>
                                <h4 className="event-name">Entrepreneurship & Cambridge A-levels</h4>
                                <span className="event-description">African Leadership Academy</span>
                                <p>Accelerated mathematics, Physics, and Economics.</p>
                            </div>
                        </div>

                        <div className="row edInst te-primary">
                            <div className='col-sm-12 col-md-3'>
                            <img width="175" height="175" className='edImage' src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Princeton_seal.svg" alt="Italian Trulli" />
                            </div>
                            <div className='col-sm-12 col-md-9 edText'>
                                <h5 className="event-date">2020-2024</h5>
                                <h4 className="event-name">Computer Science</h4>
                                <span className="event-description">Princeton University</span>
                                <p>Minor: Applied and Computational Mathematics.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-4 subpage-block">
                    <SkillBars />
                </div>
            </div> */}
        </div>
    )
}

export default Resume
