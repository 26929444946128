import Header from './components/Header'
import Footer from './components/Footer'
import Contact from './components/Contact'
import About from './components/About'
import Resume from './components/Resume'
// import Projects from './components/Projects'
// import ResumeSec from './components/ResumeSec'


function App() {
  return (
    <div className="">
      <Header />
      <About />
      <Resume />
      <Contact />
      <Footer />


    </div>
  );
}

export default App;
