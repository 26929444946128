import React from 'react'
// import { Block, BlockTitle } from 'konsta/react';
// import ProjectCard from './ProjectCard';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Projects = () => {
    // const projects = [
    //     { index: 0, url: "Education", title: "Trenton Food Finder" },
    //     { index: 1, url: "Cruise", title: "Trenton Food Finder" },
    //     { index: 2, url: "Personal Website", title: "Trenton Food Finder" },
    //     { index: 3, url: "skills", title: "Trenton Food Finder" },
    //     { index: 4, url: "Interests", title: "Trenton Food Finder" }
    // ];

    return (
        <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
        >
            <SwiperSlide>
                <div className="how-section1">
                    <a href='https://trentonfoodfinder.herokuapp.com/'>
                        <div className="row">
                            <div className="col-md-5 how-img">
                                <img width='204px' height='204px' src="https://www.trentonian.com/wp-content/uploads/2021/08/f765b122d98eae674c6c80b89e04ee75.jpg?w=620" className="img-fluid" alt="project img" />
                            </div>
                            <div className="col-md-7">
                                <h4>Trenton Food Finder</h4>
                                <h4 className="subheading">A directory for free food distribution sites with an adiministrative access for Trenton Health Team.</h4>
                                <p className="text-muted">As part of my Advanced Programming Techniques class, Trenton Food Finder, a three-tier web application, was developed in a group of three sophmores, Roosevelt
                                    Scaggs, Liam Esparaguerra, and myself. The application has mulpitple features including an interactive map, anonymous user data, third-party access granted by Trenton Health Team,
                                    and full control of the directory from an admin interface.</p>
                                <p className="text-muted">Trenton Food Finder is a three-tier web application that uses a combination of <b>flask </b> backend microframework,
                                    <b> MySQL</b> as the database management system, and <b>Bootsrap</b> and <b>JQuery</b> for frontend UI styling.</p>
                            </div>
                        </div>
                    </a>
                </div>

            </SwiperSlide>
            <SwiperSlide>
                <div className="how-section1">
                    {/* <a href='cruise.png'> */}
                    <div className="row">
                        <div className="col-md-5 how-img">
                            <img width='204px' height='204px' src="cruise.png" className="img-fluid" alt="project img" />
                        </div>
                        <div className="col-md-7">
                            <h4>Cruise</h4>
                            <h4 className="subheading">A chrome extension that helps professional writers predict their writing roadblocks.</h4>
                            <p className="text-muted">Cruise is a startup that was founded in late 2021 as part of Princeton NeuroTech club.
                                The strtup was cofounded by two Princeton students, Leila Hudson and I. We are aiming to help writers predict (and overcome)
                                their thinking roadbloacks using their keyboard input. Cruise is a chrome extension that is linked to Google Docs so that writers do not have
                                to change their writing habits.</p>
                            <p className="text-muted">Cruise is a chrome extension that uses <b>React</b> and <b>React-Bootsrap</b> for frontend and <b>flask </b> backend microframework
                                and we are in the process to add machine learning. We used <b>Selenium</b> for Google Docs testing. </p>
                        </div>
                    </div>
                    {/* </a> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="how-section1">
                    {/* <a href='cruise.png'> */}
                    <div className="row">
                        <div className="col-md-5 how-img">
                            <img width='204px' height='204px' src="mat.png" className="img-fluid" alt="project img" />
                        </div>
                        <div className="col-md-7">
                            <h4>Personal Website</h4>
                            <h4 className="subheading">Rebuilt Personal website from Vanilla Javascript to using React.</h4>
                            <p className="text-muted">Deployed on <b>Netlify</b>, mat was first built with basic Javascript, HTML, 
                            and CSS as my first website. Having the full autonomy over the project allowed me to experiment with multiple 
                            technologies such as <b>Angular JS</b> then choose to continue with <b>React</b>. 
                            Thanks to this website, I was able to try and practice technologies I was curious about or tevhmologies 
                            that were recommended in tech-talks or friends such as SEO, React, and Netlify.</p>
                            <p className="text-muted">Through this project, I was able to experiment with mulpitple sorts of libraries such as Material UI, Swiper, and other npm packages. For styling, I chose to work with <b>CSS</b> and <b>React-Boostrap</b>.</p>
                        </div>
                    </div>
                    {/* </a> */}
                </div>
            </SwiperSlide>

        </Swiper >


    )
}

export default Projects