import React from 'react'

const Contact = () => {
    return (
        <section id="contact" className="">

            <div className="row section-intro">
                <div className="col-twelve">

                    <h5>Contact</h5>
                    <h1>I'd Love To Connect With You.</h1>

                    <p className="lead">Let me get to know more about you.</p>

                </div>
            </div>


            <div className="row contact-info">
                <div className="col-md-4 col-sm-12 tab-full">

                    <div className="icon">
                        <i className="fa fa-location-arrow"></i>
                    </div>

                    <h5>Where to find me</h5>

                    <p>
                        Princeton University<br></br>
                        Princeton, NJ<br></br>
                        08544 US
                    </p>

                </div>

                <div className="col-md-4 col-sm-12 tab-full">

                    <div className="icon">
                        <i className="fa fa-envelope"></i>
                    </div>

                    <h5>Email Me At</h5>

                    <p>mtouil@princeton.edu<br></br>
                    </p>

                </div>

                <div className="col-md-4 col-sm-12 tab-full">

                    <div className="icon">
                        <i className="fab fa-linkedin"></i>
                    </div>

                    <h5>Let's Connect</h5>
                    <a href="https://www.linkedin.com/in/mohamed-amir-touil/" style={{color:"#636363"}}>LinkedIn Profile</a>

                </div>

            </div>


        </section>
    )
}

export default Contact
