import React from 'react'
// const black = '#313131';
const About = () => {
    return (
        <div className='containe'>
            <section className="row about-wrapper" id="about">
                <div className="col about-left">
                    <div className="about-left-content">
                        <div>
                            <div className="shadow">
                                <div className="about-img">
                                    <img src="Amir.jpg"
                                        alt="Amir">
                                    </img>
                                </div>
                            </div>

                            <h2>Mohamed Amir Touil</h2>
                            <h3>CS, Princeton University</h3>
                        </div>

                        {/* <ul className="icons">
                            <li><i className="fab fa-facebook-f"></i></li>
                            <li><i className="fab fa-twitter"></i></li>
                            <li><i className="fab fa-linkedin"></i></li>
                            <li><i className="fab fa-instagram"></i></li>
                        </ul> */}
                    </div>

                </div>

                <div className="col about-right">
                    <h1>Hello<span>!</span></h1>
                    <h2>Here's who I am & what I do</h2>
                    <div className="about-btns">
                        <button type="button" className="btn btn-orange" onClick={()=>{console.log('hi')}}>resume / CV</button>
                        <a href='https://github.com/AmirTouilPU'><button type="button" className="btn btn-white">Git hub</button></a>
                    </div>

                    <div className="about-para">
                        <p>I am Amir Touil, a sophmore international student in the computer science department at Princeton University. I am also minoring in Applied and Computational Mathematics.</p> <br/>
                        <p>In and out of class, I am pursuing my interest in combining mathematics and computer science. I am passionate about both web development and theoretical CS and algorithm designs to create products with efficent functionalities.</p>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default About
