const Footer = () => {
    return (
        <footer>

            <div className="row footerRow">

                <div className="twelve columns">

                    <ul className="copyright">
                        <li>Mohamed Amir Touil</li>
                        <li>© Copyright 2022</li>
                    </ul>

                </div>

                <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i
                    className="fas fa-angle-up"></i></a>
                </div>

            </div>

        </footer>
    )
}

export default Footer
