import TypeWriterEffect from 'react-typewriter-effect';

const Header = () => {

    return (
        <header id="home">
            <nav id="nav-wrap" className="" style={{ textAlign: "center" }}>

                <ul id="nav" className="nav">

                    <li className="mat" id="mat"><a className="underline-hover-effect" href="#mat">mat</a></li>
                    <li className=""><a className="underline-hover-effect" href="#about">about</a></li>
                    <li className=""><a className="underline-hover-effect" href="#Resume">Resume</a></li>
                    {/* <li className=""><a className="underline-hover-effect" href="#education">education</a></li> */}
                    <li className=""><a className="underline-hover-effect" href="#contact">contact</a></li>

                </ul>
            </nav>

            <div className="row banner">
                <div className="banner-text">
                    <h1 className="responsive-headline"> 
                    
                    <TypeWriterEffect
                        // textStyle={{
                        //     fontFamily: 'Red Hat Display',
                        //     color: '#3F3D56',
                        //     fontWeight: 500,
                        //     fontSize: '1.5em',
                        // }}
                        startDelay={0}
                        cursorColor="#f58025"
                        multiText={[
                            'Mohamed Amir Touil',
                            'محمد أمير الطويل',
                        ]}
                        multiTextLoop={true}
                        multiTextDelay={2000}
                        typeSpeed={100}
                    /> 
                    </h1>
                    <h3><span>Software Engineer</span> | <span>Data Scientist</span> </h3>
                    <h3><span>background pc: Sidi Bou Said, Tunisia.<span></span></span></h3>

                    <ul className="social">
                        <li><a href="https://github.com/AmirTouilPU"><i className="fab fa-github"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/mohamed-amir-touil/"><i
                            className="fab fa-linkedin"></i></a>
                        </li>
                        <li><a href="mailto:mtouil@princeton.edu"><i className="fa fa-envelope"></i></a></li>
                    </ul>
                </div>
            </div>
            <p className="scrolldown">
                <a id="go-to-about" className="smoothscroll" href="#about"><i className="fas fa-angle-down"></i></a>
            </p>
        </header>
    )
}

export default Header
